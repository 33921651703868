/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.bar-chart,
h1,
h2,
h3,
h4,
h5 {
    -webkit-user-select: none;
    -ms-user-select: none
}

.bar-chart,
button,
h1,
h2,
h3,
h4,
h5 {
    -ms-user-select: none
}

.input input:focus,
.input textarea:focus,
button {
    outline: 0
}

.bar-chart-bar:hover,
.checkbox label:hover,
.combo-arrow:hover,
.days li:hover,
.dropdown-overlay:hover,
.dropzone label:hover,
.dropzone:hover,
.filter-overlay:hover,
.filter-trigger span.has-filter:hover,
.filter-trigger span.no-filter:hover,
.input .clear:hover,
.line-chart:hover,
.livestream .icon:hover,
.menu li:hover,
.modal .steps-indicator li:hover,
.options .option:hover,
.options-add-icon svg:hover,
.segments li:hover,
.select:hover,
.switch label:before,
.switch label:hover,
.tabs a:hover,
.tabs li a:hover,
.tabs li:hover,
.tabs span:hover,
button.combo:hover,
button.tertiary:hover,
button:hover {
    cursor: pointer
}

.line-chart {
    position: relative;
    display: block;
    background-color: #fff
}

.line-chart .line,
.line-chart-bg-line {
    background-color: #e0e0e0;
    z-index: 0;
    position: absolute
}

.line-chart .line {
    height: 100%;
    right: 0;
    top: 0;
    width: 1px
}

.line-chart-bg-line {
    width: 100%;
    height: 1px
}

.line-chart-lines-container {
    z-index: 0;
    position: absolute;
    display: block;
    top: 0;
    width: 100%;
    height: 100%;
    border-right: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0
}

.pointer {
    height: 16px;
    width: 16px;
    border-radius: 50em;
    right: -8px;
    background-color: transparent;
    position: absolute;
    z-index: 1
}

.pointer span {
    position: absolute;
    background-color: #DE5078;
    border-radius: 50em;
    height: 10px;
    width: 10px;
    top: 3px;
    left: 3px
}

.pointer.active {
    background-color: #DE5078;
    -webkit-box-shadow: rgba(0, 0, 0, .2) 0 2px 10px;
    box-shadow: rgba(0, 0, 0, .2) 0 2px 10px
}

.data-modal,
.event-card:hover {
    -webkit-box-shadow: rgba(0, 0, 0, .2) 0 2px 24px
}

.pointer.active span {
    background-color: #fff
}

.pointer.people span,
.pointer.people.active {
    background-color: #F9B94B
}

.bar-chart,
.pointer.people.active span {
    background-color: #fff
}

.data-modal {
    box-shadow: rgba(0, 0, 0, .2) 0 2px 24px;
    min-width: 200px;
    z-index: 9
}

.data-modal:hover {
    pointer-events: none
}

.data-modal.left {
    right: 24px
}

.data-modal.right {
    left: 64px
}

.bar-chart {
    -moz-user-select: none;
    user-select: none;
    padding: 24px;
    border-radius: 4px
}

.bar-chart-column {
    display: inline-block;
    position: relative;
    width: 14.285714%
}

.bar-chart-bar,
.drag-bar {
    display: block;
    position: relative
}

.bar-chart-bar {
    background-color: #6A4AFC;
    width: calc(100% - 8px);
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    -webkit-transition: .3s all;
    transition: .3s all
}

.drag-bar {
    margin: 0;
    padding: 0;
    height: 100%
}

.days li,
.drag-bar li,
.weekdays li {
    display: inline-block
}

.drag-bar li {
    background-color: #E3E3E3;
    position: relative;
    margin: 1px;
    width: calc(5% - 2px)
}

.checkbox,
img {
    display: block
}

.drag-bar li.gone {
    background-color: red
}

.bg-white {
    background-color: #fff
}

.bg-error {
    background-color: #DE5833
}

.white {
    color: #fff
}

.grey9 {
    color: #1A1919
}

.grey8 {
    color: #242323
}

.grey7 {
    color: #5C5C5C
}

.grey6 {
    color: #858282
}

.grey4,
.grey5 {
    color: #999899
}

.grey3 {
    color: #C8C7C8
}

.grey2 {
    color: #E3E3E3
}

.grey1 {
    color: #FBFBFB
}

.bg-grey9 {
    background-color: #1A1919
}

.bg-grey8 {
    background-color: #242323
}

.bg-grey7 {
    background-color: #5C5C5C
}

.bg-grey6 {
    background-color: #858282
}

.bg-grey4,
.bg-grey5 {
    background-color: #999899
}

.bg-grey3 {
    background-color: #C8C7C8
}

.bg-grey2 {
    background-color: #E3E3E3
}

.bg-grey1 {
    background-color: #FBFBFB
}

*,
::after,
::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

::-moz-selection {
    color: #fff;
    background: #000
}

::selection {
    color: #fff;
    background: #000
}

body,
html {
    background-color: #f9f9f9;
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-family: 'Atlas Grotesk Regular';
    font-style: normal;
    color: #1A1D20
}

@media (min-width:48em) {
    body,
    html {
        font-size: 14px
    }
}

a {
    text-decoration: none
}

img {
    position: relative;
    max-width: 100%
}

@font-face {
    font-family: 'Atlas Grotesk Medium';
    src: url(../fonts/AtlasGroteskWeb-Medium.woff2) format('woff2'), url(../fonts/AtlasGroteskWeb-Medium.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Atlas Grotesk Regular';
    src: url(../fonts/AtlasGroteskWeb-Regular.woff2) format('woff2'), url(../fonts/AtlasGroteskWeb-Regular.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

.fontBold {
    font-family: 'Atlas Grotesk Medium';
    font-style: normal
}

.days li,
.empty-state p,
.fontRegular,
.input label,
button,
p {
    font-family: 'Atlas Grotesk Regular';
    font-style: normal
}

.fontSize5 {
    font-size: calc(.25vw + 48px);
    line-height: 1.3;
    letter-spacing: -.05em
}

.fontSize4 {
    font-size: calc(.25vw + 32px);
    line-height: 1.4;
    letter-spacing: -.04em
}

.fontSize2,
.fontSize3 {
    letter-spacing: -.03em
}

.fontSize3 {
    font-size: calc(.25vw + 24px);
    line-height: 1.4
}

.fontSize2 {
    font-size: calc(.25vw + 16px);
    line-height: 1.3
}

.fontSize1 {
    font-size: calc(.25vw + 14px);
    line-height: 1.2;
    letter-spacing: -.02em
}

.fontSize0,
.fontSizeN1,
button,
p {
    line-height: 1.5
}

.fontSize0 {
    font-size: 14px
}

.fontSizeN1 {
    font-size: 13px
}

p {
    font-size: 14px
}

h1,
h2,
h3,
h4,
h5 {
    -moz-user-select: none;
    user-select: none
}

.checkbox label,
button {
    -webkit-user-select: none;
    -moz-user-select: none
}

button {
    background-color: #6A4AFC;
    border-radius: 4px;
    border: none;
    color: #fff;
    font-size: 14px;
    padding: 10px 22px;
    -webkit-transition: .3s all;
    transition: .3s all;
    user-select: none
}

button:hover {
    background-color: #6F37C6
}

button:disabled {
    opacity: .5;
    pointer-events: none
}

button .icon {
    margin-left: 8px
}

button .icon svg {
    padding: 3px
}

button.primary {
    border: 2px solid #6A4AFC
}

button.primary:hover {
    border: 2px solid #6F37C6
}

button.secondary {
    border: 2px solid #6A4AFC;
    background-color: #fff;
    color: #6A4AFC
}

button.secondary:hover {
    border: 2px solid #6F37C6;
    color: #6F37C6
}

.error,
button.delete {
    color: #DE5833
}

button.delete {
    border: 2px solid #DE5833;
    background-color: #fff
}

.tabs a.active,
.tabs li.active,
button.link {
    border-bottom: 2px solid #6A4AFC
}

button.delete:hover {
    color: #fff;
    background-color: #DE5833
}

button.tertiary {
    background-color: #fff;
    color: #242323
}

button.tertiary:hover {
    background-color: #E3E3E3
}

button.link {
    background-color: transparent;
    padding: 2px 0;
    color: #6A4AFC;
    border-radius: 0
}

button.link:hover {
    background-color: transparent;
    color: #6F37C6;
    border-bottom: 2px solid #6F37C6
}

button.combo {
    background-color: #fff;
    color: #242323;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid #C8C7C8
}

button.combo:hover {
    background-color: #E3E3E3
}

.combo-arrow {
    background-color: #fff;
    color: #242323;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    line-height: 0;
    padding: 9px 8px 8px
}

.combo-arrow:hover,
.days li.hover span,
.days li.hover span.end {
    background-color: #E3E3E3
}

.combo-arrow svg {
    padding: 4px
}

button.dark {
    background-color: #242323;
    color: #fff
}

.livestream .toolbar {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    padding: 0 6px;
    background-color: #2B333F;
    background-color: rgba(43, 51, 63, .7)
}

.livestream .icon svg {
    border-radius: 50em;
    padding: 6px;
    color: #fff;
    height: 28px;
    width: 28px;
    margin: 1px;
    -webkit-transition: .3s all;
    transition: .3s all
}

.livestream:hover .toolbar {
    opacity: 1
}

.checkbox {
    position: relative
}

.checkbox input {
    position: absolute;
    opacity: 0
}

.checkbox input:checked+label:before {
    background-color: #6A4AFC;
    border: 1px solid #6A4AFC
}

.checkbox label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: calc(8px * 3 + 16px);
    height: 24px;
    line-height: 1;
    -ms-user-select: none;
    user-select: none;
    padding-top: 0 !important
}

.days li,
.input label,
.month {
    font-size: 13px;
    line-height: 1.5
}

.month,
.weekdays {
    -webkit-user-select: none;
    -moz-user-select: none
}

.checkbox label:before {
    content: "";
    width: calc(8px * 3);
    height: calc(8px * 3);
    display: block;
    border-radius: 3px;
    position: absolute;
    left: 0;
    background-color: #fff;
    border: 1px solid #E3E3E3
}

.checkbox label:after {
    content: "";
    display: block;
    width: 8px;
    height: 14px;
    -webkit-box-shadow: 2px 2px #fff;
    box-shadow: 2px 2px #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: .3s all;
    transition: .3s all;
    position: absolute;
    left: 8px;
    top: 0
}

.days li,
.weekdays li {
    width: 14.2%;
    text-align: center
}

.checkbox label:hover:before {
    border: 1px solid #6A4AFC
}

.month {
    text-align: center;
    margin-bottom: 8px;
    padding-top: 8px;
    -ms-user-select: none;
    user-select: none
}

@media (max-width:47.9375em) {
    .date-time .input {
        padding-bottom: 0;
        margin-bottom: 8px
    }
    .calendar {
        margin-bottom: 24px
    }
}

@media (min-width:48em) {
    .calendar {
        position: relative;
        display: inline-block
    }
}

.calendar:nth-of-type(2) {
    display: none
}

@media (min-width:37.5em) {
    .calendar .single-calendar-next-button {
        display: none
    }
    .calendar:nth-of-type(2) {
        display: block
    }
}

.weekdays {
    margin: 0 0 16px;
    padding: 10px 0;
    -ms-user-select: none;
    user-select: none
}

.days li,
.dropdown button {
    -webkit-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none
}

.weekdays li {
    color: #242323
}

.days {
    padding: 0;
    margin: 0
}

.days li,
.filter,
.options .option {
    margin-bottom: 8px
}

.days li {
    color: #5C5C5C;
    list-style-type: none;
    user-select: none;
    overflow: hidden
}

.days li span {
    width: 100%;
    display: block;
    position: relative;
    padding: 8px 0;
    z-index: 1
}

.days li span.end:after,
.days li span.end:before,
.days li span.start:after,
.days li span.start:before {
    position: absolute;
    height: 100%;
    top: 0;
    content: ''
}

.days li span.start {
    color: #fff;
    border-top-left-radius: 50em;
    border-bottom-left-radius: 50em;
    overflow: hidden
}

.days li span.start:after {
    background-color: #6A4AFC;
    border-radius: 50em;
    left: 0;
    width: 100%;
    z-index: -1
}

.days li span.start:before {
    background-color: #E3E3E3;
    right: 0;
    width: 50%;
    z-index: -2
}

.days li span.end {
    color: #fff;
    border-top-right-radius: 50em;
    border-bottom-right-radius: 50em;
    overflow: hidden
}

.days li span.end:after {
    background-color: #242323;
    border-radius: 50em;
    left: 0;
    width: 100%;
    z-index: -1
}

.days li span.end:before {
    background-color: #E3E3E3;
    left: 0;
    width: 50%;
    z-index: -2
}

.days li span.in-range {
    background-color: #E3E3E3;
    color: #999899
}

.days li span.disabled {
    pointer-events: none;
    opacity: .4
}

.dropdown:focus-within button {
    border: 2px solid #858282
}

.dropdown button {
    background-color: #242323;
    color: #E3E3E3;
    user-select: none;
    border: 2px solid transparent;
    padding: 8px 22px
}

.dropdown button .icon {
    margin-left: 16px;
    margin-right: -8px
}

.dropdown button:hover {
    background-color: #5C5C5C;
    color: #fff
}

.dropdown-children {
    display: block;
    position: relative;
    z-index: 4
}

.dropdown-overlay {
    background-color: rgba(0, 0, 0, 0);
    display: block;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3
}

.dropdown.light button {
    background-color: #E3E3E3;
    color: #242323
}

.modal .dropdown-overlay {
    display: none
}

.filters {
    margin-top: 16px;
    margin-left: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow-x: auto;
    width: 100vw;
    padding-right: 24px
}

@media (min-width:48em) {
    .filters {
        margin-top: 8px;
        display: block;
        overflow: visible
    }
}

.filter {
    margin-top: 8px;
    margin-left: 8px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (min-width:48em) {
    .filter {
        position: relative;
        display: inline-block
    }
}

.filter-trigger {
    display: inline-block;
    position: relative
}

.filter-trigger span.has-filter,
.filter-trigger span.no-filter {
    background-color: #fff;
    border-radius: 50em;
    border: 1px solid #E3E3E3;
    display: inline-block;
    position: relative;
    padding: 10px 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: .3s all;
    transition: .3s all
}

.options,
.select {
    -webkit-user-select: none;
    -moz-user-select: none
}

.filter-trigger span.has-filter:hover,
.filter-trigger span.no-filter:hover {
    border: 1px solid #999899
}

.filter-trigger span.has-filter .count,
.filter-trigger span.no-filter .count {
    position: absolute;
    top: -10px;
    right: -10px;
    height: 28px;
    width: 28px;
    background: #fff;
    color: #242323;
    font-size: 14px;
    font-weight: 900;
    border-radius: 50%;
    text-indent: 0;
    -webkit-transition: -webkit-transform .2s .5s;
    transition: transform .2s .5s;
    transition: transform .2s .5s, -webkit-transform .2s .5s;
    -webkit-font-smoothing: antialiased
}

.filter-trigger span.has-filter .count .number,
.filter-trigger span.no-filter .count .number {
    position: absolute;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%)
}

.filter-trigger span.has-filter {
    background-color: #242323;
    color: #fff;
    border: 1px solid #242323
}

.filter-trigger.is-visible {
    z-index: 3
}

.filter-content {
    background-color: #fff;
    border-radius: 8px;
    position: absolute;
    z-index: 3;
    -webkit-box-shadow: rgba(0, 0, 0, .27) 0 2px 44px;
    box-shadow: rgba(0, 0, 0, .27) 0 2px 44px
}

@media (max-width:47.9375em) {
    .filter-content {
        top: 162px;
        left: 20px;
        width: calc(100% - 40px) !important
    }
}

.modal,
.modal-overlay {
    width: 100vw;
    position: fixed
}

@media (min-width:81.25em) {
    .filter-content {
        top: 162px;
        left: 0
    }
}

@media (min-width:48em) {
    .filter-content {
        top: 46px
    }
    .filters .filter:last-of-type .filter-content,
    .filters .filter:nth-of-type(5) .filter-content {
        right: 0
    }
}

.filter-content .content {
    position: relative;
    display: block;
    min-height: 200px;
    overflow: visible
}

.filter-content .content .preview {
    position: absolute;
    border-radius: 8px;
    left: 72px;
    top: 0;
    pointer-events: none;
    -webkit-box-shadow: rgba(0, 0, 0, .2) 0 2px 16px;
    box-shadow: rgba(0, 0, 0, .2) 0 2px 16px;
    z-index: 999
}

.filter-content .footer {
    border-top: 1px solid #E3E3E3;
    padding: 16px
}

.filter-overlay {
    position: fixed;
    z-index: 2;
    background-color: rgba(0, 0, 0, .6);
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0
}

.filters::-webkit-scrollbar {
    display: none
}

.options {
    -ms-user-select: none;
    user-select: none
}

.options .option {
    border-radius: 50em;
    display: inline-block;
    padding: 10px 22px;
    background-color: #fff;
    border: 1px solid #E3E3E3;
    margin-right: 8px;
    -webkit-transition: .2s all;
    transition: .2s all
}

.options .option:hover {
    border: 1px solid #6A4AFC;
    color: #6A4AFC
}

.options .option.is-active {
    background-color: #6A4AFC;
    border: 1px solid #6A4AFC;
    color: #fff
}

.options-add-icon {
    display: inline-block;
    position: relative;
    margin-bottom: -14px
}

.options-add-icon svg {
    border-radius: 50em;
    height: 39px;
    width: 39px;
    padding: 8px 9px;
    position: relative;
    display: block;
    background-color: #fff;
    border: 1px solid #E3E3E3;
    margin-right: 8px;
    -webkit-transition: .2s all;
    transition: .2s all
}

.options-add-icon svg:hover {
    border: 1px solid #6A4AFC;
    color: #6A4AFC
}

.select {
    -ms-user-select: none;
    user-select: none
}

.input label,
.switch label,
.tabs a,
.tabs li,
.tabs span {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none
}

.select p {
    width: 330px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.select-image {
    height: 64px;
    width: 64px;
    border-radius: 4px;
    display: block;
    position: relative;
    overflow: hidden;
    background-position: center center;
    background-size: cover
}

.select-image.lg {
    height: 72px;
    width: 72px
}

.select-image.is-selected:before {
    background-color: #6A4AFC;
    content: '';
    position: absolute;
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    border-radius: 50em;
    top: calc(50% - 16px);
    left: calc(50% - 16px);
    z-index: 1
}

.clearfix::after,
.event-card .type-wrapper:before,
.grid-full::after,
.grid::after,
.select-image.is-selected:after,
.switch label:after,
.switch label:before {
    content: ""
}

.select-image.is-selected:after {
    display: block;
    width: 8px;
    height: 14px;
    -webkit-box-shadow: 2px 2px #fff;
    box-shadow: 2px 2px #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    left: calc(50% - 4px);
    top: calc(50% - 11px);
    z-index: 1
}

.select .overlay {
    position: absolute;
    background-color: rgba(255, 255, 255, .7);
    height: 100%;
    width: 100%
}

.input {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
    border: 2px solid #C8C7C8;
    border-radius: 4px;
    -webkit-transition: .2s all;
    transition: .2s all
}

.segments,
.tags {
    -webkit-box-align: start
}

.dropzone,
.input label {
    -webkit-transition: .2s all
}

.input label {
    color: #5C5C5C;
    padding-top: 8px;
    padding-left: 16px;
    width: 100%;
    user-select: none;
    transition: .2s all
}

.input input,
.input textarea {
    border: none;
    font-size: calc(.25vw + 14px);
    line-height: 1.2;
    letter-spacing: -.02em;
    width: 100%;
    padding-bottom: 8px;
    padding-left: 16px
}

.input input:focus .input:focus-within,
.input textarea:focus .input:focus-within {
    background-color: #6A4AFC
}

.input .clear {
    position: absolute;
    right: 8px;
    bottom: 6px
}

.input .clear:hover {
    color: #6A4AFC
}

.input:focus-within {
    border: 2px solid #6A4AFC
}

.input:focus-within label {
    color: #6A4AFC
}

.input.dark {
    background-color: #242323;
    border: none
}

.input.dark input,
.input.dark label {
    background-color: transparent;
    color: #E3E3E3
}

.tags {
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.tags .tag {
    background-color: #000;
    color: #fff;
    padding: 2px 8px;
    border-radius: 50em;
    margin-right: 8px
}

.dropzone {
    padding: 48px;
    background-color: #FBFBFB;
    border-style: dashed;
    border-color: #C8C7C8;
    border-radius: 4px;
    transition: .2s all
}

.dropzone.dragged,
.dropzone:hover {
    border-color: #6A4AFC
}

.dropzone label {
    width: 100%;
    position: relative;
    display: block;
    text-align: center
}

.dropzone input {
    display: none
}

.dropzone.dragged label {
    pointer-events: none
}

.switch {
    display: block;
    position: relative;
}

.flex,
.switch label {
    display: -webkit-box;
    display: -ms-flexbox
}

.switch input {
    position: absolute;
    opacity: 0
}

.switch input:checked+label:before {
    background-color: #6A4AFC;
    border: 1px solid #6A4AFC
}

.switch input:checked+label:after {
    background-color: #fff;
    border: 1px solid #6A4AFC;
    -webkit-transform: translateX(calc(48px - 16px - 4px * 2));
    transform: translateX(calc(48px - 16px - 4px * 2))
}

.switch label {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: calc(8px * 5 + 16px);
    height: 24px;
    line-height: 1;
    user-select: none
}

.switch label:before {
    position: absolute;
    left: 0;
    display: inline-block;
    width: 48px;
    height: 24px;
    background-color: #E3E3E3;
    border-radius: 50em
}

.switch label:after {
    width: 16px;
    height: 16px;
    background-color: #858282;
    display: block;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 50%;
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s
}

.distribute-inline,
.segments {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox
}

.switch label:focus:before {
    border: 2px solid red
}

.switch.dark label {
    color: #fff
}

.switch.dark label:before {
    background-color: #5C5C5C
}

.switch.dark label:after {
    background-color: #FBFBFB
}

.segments {
    -ms-flex-align: start;
    align-items: flex-start;
    display: inline-flex;
    list-style: none;
    background-color: #E3E3E3;
    border-radius: 4px;
    padding: 6px;
    margin: 0
}

.segments li {
    font-size: 14px;
    line-height: 1.5;
    overflow: hidden;
    border-radius: 4px;
    padding: 8px 24px
}

.segments li.active {
    background-color: #fff;
    -webkit-box-shadow: rgba(0, 0, 0, .1) 0 2px 8px;
    box-shadow: rgba(0, 0, 0, .1) 0 2px 8px
}

.offset-sidenav {
    width: calc(100vw - 300px)
}

.container--people {
    width: 1410px;
    max-width: calc(100% - 32px);
    margin-right: auto;
    margin-left: auto
}

.container--vid {
    width: 1315px;
    max-width: calc(100% - 32px);
    margin-right: auto;
    margin-left: auto
}

.container,
.container--lg,
.container--md,
.container--sm {
    max-width: 96%;
    margin-right: auto;
    margin-left: auto
}

.container {
    width: 1280px
}

.container--lg {
    width: 1024px
}

.container--md {
    width: 860px
}

.container--sm {
    width: 614px
}

.full-height {
    height: calc(100vh - 180px)
}

.success-icon {
    background-color: #0A5;
    border-radius: 50em;
    position: relative;
    display: block
}

.success-icon svg {
    color: #fff;
    margin: 21px;
    line-height: 3;
    border-radius: 50em;
    padding: 0;
    height: 50px;
    width: 50px;
    display: block
}

.menu,
.menu li {
    border-radius: 4px
}

.success-icon svg polyline {
    line-height: 0;
    margin: 0;
    padding: 9px 0 0;
    font-size: #fff;
    position: relative;
    display: block
}

.empty-state {
    text-align: center
}

.empty-state .illustration {
    max-height: 100px;
    height: auto
}

.empty-state h2 {
    margin-bottom: 0;
    padding: 0
}

.empty-state p {
    max-width: 274px;
    text-align: center;
    margin-bottom: 24px
}

.w-lg,
.w-xl {
    max-width: 100%
}

.clearfix::after {
    clear: both;
    display: block
}

.w-sm {
    width: 128px
}

.w-md {
    width: 256px
}

.w-lg {
    width: 384px
}

.w-xl {
    width: 512px
}

.minw-sm {
    min-width: 128px
}

.minw-md {
    min-width: 256px
}

.minw-lg {
    min-width: 384px
}

.maxw-sm {
    max-width: 128px
}

.maxw-md {
    max-width: 256px
}

.maxw-lg {
    max-width: 384px
}

.flex-auto {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto
}

.flex-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
}

.distribute-vertical,
.flex-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.flex-column {
    -ms-flex-direction: column;
    flex-direction: column
}

.align-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.align-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}

.align-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.col-sm-1 {
    width: 8.33333333%
}

.col-sm-2 {
    width: 16.66666667%
}

.col-sm-3 {
    width: 25%
}

.col-sm-4 {
    width: 33.33333333%
}

.col-sm-5 {
    width: 41.66666667%
}

.col-sm-6 {
    width: 50%
}

.col-sm-7 {
    width: 58.33333333%
}

.col-sm-8 {
    width: 66.66666667%
}

.col-sm-9 {
    width: 75%
}

.col-sm-10 {
    width: 83.33333333%
}

.col-sm-11 {
    width: 91.66666667%
}

.col-sm-12,
.table {
    width: 100%
}

@media screen and (min-width:768px) {
    .col-1 {
        width: 8.33333333%
    }
    .col-2 {
        width: 16.66666667%
    }
    .col-3 {
        width: 25%
    }
    .col-4 {
        width: 33.33333333%
    }
    .col-5 {
        width: 41.66666667%
    }
    .col-6 {
        width: 50%
    }
    .col-7 {
        width: 58.33333333%
    }
    .col-8 {
        width: 66.66666667%
    }
    .col-9 {
        width: 75%
    }
    .col-10 {
        width: 83.33333333%
    }
    .col-11 {
        width: 91.66666667%
    }
    .col-12 {
        width: 100%
    }
}

.grid,
.grid-full {
    margin-left: auto;
    margin-right: auto;
    max-width: 1040px
}

.grid-full::after,
.grid::after {
    clear: both;
    display: block
}

.grid {
    padding-right: 1.5rem;
    padding-left: 1.5rem
}

@media screen and (min-width:600px) {
    .grid {
        padding-right: 2rem;
        padding-left: 2rem
    }
    .grid1of1,
    .grid1of2,
    .grid1of3,
    .grid1of4,
    .grid1of6,
    .grid2of3,
    .grid3of4,
    .grid5of6 {
        float: left;
        display: block
    }
    .grid1of1 {
        margin-right: 4.82916%;
        width: 100%
    }
    .grid1of1:last-child {
        margin-right: 0
    }
    .grid1of2 {
        margin-right: 4.82916%;
        width: 47.58542%
    }
    .grid1of2:last-child {
        margin-right: 0
    }
    .grid1of3 {
        margin-right: 4.82916%;
        width: 30.11389%
    }
    .grid1of3:last-child,
    .grid1of3:nth-child(3n+3) {
        margin-right: 0
    }
    .grid2of3 {
        margin-right: 4.82916%;
        width: 65.05695%
    }
    .grid2of3:last-child {
        margin-right: 0
    }
    .grid1of4 {
        margin-right: 4.82916%;
        width: 47.58542%
    }
    .grid1of4:last-child,
    .grid1of4:nth-child(2n) {
        margin-right: 0
    }
    .grid3of4 {
        margin-right: 4.82916%;
        width: 100%
    }
    .grid3of4:last-child {
        margin-right: 0
    }
    .grid1of6 {
        margin-right: 4.82916%;
        width: 30.11389%
    }
    .grid1of6:last-child,
    .grid1of6:nth-child(3n) {
        margin-right: 0
    }
    .grid5of6 {
        margin-right: 4.82916%;
        width: 65.05695%
    }
    .grid1of12,
    .grid5of12 {
        width: 30.11389%;
        float: left;
        display: block
    }
    .grid5of6:last-child {
        margin-right: 0
    }
    .grid1of12 {
        margin-right: 4.82916%
    }
    .grid1of12:last-child,
    .grid1of12:nth-child(3n) {
        margin-right: 0
    }
    .grid5of12 {
        margin-right: 4.82916%
    }
    .grid5of12:last-child {
        margin-right: 0
    }
    .grid7of12 {
        float: left;
        display: block;
        margin-right: 4.82916%;
        width: 65.05695%
    }
    .grid7of12:last-child {
        margin-right: 0
    }
}

@media screen and (min-width:1201px) {
    .grid1of1,
    .grid1of2,
    .grid1of3,
    .grid2of3 {
        float: left;
        display: block
    }
    .grid1of1 {
        margin-right: 2.35765%;
        width: 100%
    }
    .grid1of1:last-child {
        margin-right: 0
    }
    .grid1of2 {
        margin-right: 2.35765%;
        width: 48.82117%
    }
    .grid1of2:last-child {
        margin-right: 0
    }
    .grid1of3 {
        margin-right: 1.333%;
        width: 32.4%
    }
    .grid1of3:last-child {
        margin-right: 0
    }
    .grid2of3 {
        margin-right: 2.35765%;
        width: 65.88078%
    }
    .grid1of4,
    .grid1of4:nth-child(2n) {
        width: 23.23176%;
        float: left;
        display: block
    }
    .grid2of3:last-child {
        margin-right: 0
    }
    .grid1of4 {
        margin-right: 2.35765%
    }
    .grid1of4:last-child {
        margin-right: 0
    }
    .grid1of4:nth-child(2n) {
        margin-right: 2.35765%
    }
    .grid1of4:nth-child(2n):last-child,
    .grid1of4:nth-child(4n) {
        margin-right: 0
    }
    .grid3of4 {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 74.41059%
    }
    .grid1of6,
    .grid1of6:nth-child(3n) {
        width: 14.70196%;
        float: left;
        display: block
    }
    .grid3of4:last-child {
        margin-right: 0
    }
    .grid1of6 {
        margin-right: 2.35765%
    }
    .grid1of6:last-child {
        margin-right: 0
    }
    .grid1of6:nth-child(3n) {
        margin-right: 2.35765%
    }
    .grid1of6:nth-child(3n):last-child,
    .grid1of6:nth-child(6n) {
        margin-right: 0
    }
    .grid5of6 {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 82.94039%
    }
    .grid1of12,
    .grid1of12:nth-child(3n) {
        width: 6.17215%;
        float: left;
        display: block
    }
    .grid5of6:last-child {
        margin-right: 0
    }
    .grid1of12 {
        margin-right: 2.35765%
    }
    .grid1of12:last-child {
        margin-right: 0
    }
    .grid1of12:nth-child(3n) {
        margin-right: 2.35765%
    }
    .grid1of12:nth-child(12n),
    .grid1of12:nth-child(3n):last-child {
        margin-right: 0
    }
    .grid5of12 {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 40.29137%
    }
    .grid5of12:last-child {
        margin-right: 0
    }
    .grid7of12 {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 57.35098%
    }
    .grid7of12:last-child {
        margin-right: 0
    }
}

.end-row {
    margin-right: 0
}

.offset1of12 {
    margin-left: 8.5298%;
    @include shift(1 of 12);
}

.offset1of6 {
    margin-left: 17.05961%;
    @include shift(2 of 12);
}

.offset1of4 {
    margin-left: 25.58941%;
    @include shift(3 of 12);
}

.offset1of3 {
    margin-left: 34.11922%;
    @include shift(4 of 12);
}

.offset5of12 {
    margin-left: 42.64902%;
    @include shift(5 of 12);
    @include shift(10 of 12);
}

.offset1of2 {
    margin-left: 51.17883%;
    @include shift(6 of 12);
}

.offset7of12 {
    margin-left: 59.70863%;
    @include shift(7 of 12);
}

.offset2of3 {
    margin-left: 68.23843%;
    @include shift(8 of 12);
}

.offset3of4 {
    margin-left: 76.76824%;
    @include shift(9 of 12);
}

.offset5of6 {
    margin-left: 85.29804%
}

.offset11of12 {
    margin-left: 93.82785%;
    @include shift(11 of 12);
}

@media (min-width:48em) {
    .grid1of12.noGutter {
        width: 8.333%;
        margin-right: 0
    }
    .grid1of6.noGutter {
        width: 16.666%;
        margin-right: 0
    }
    .grid1of4.noGutter {
        width: 25%;
        margin-right: 0
    }
    .grid1of3.noGutter {
        width: 33.333%;
        margin-right: 0
    }
    .grid5of12.noGutter {
        width: 41.666%;
        margin-right: 0
    }
    .grid1of2.noGutter {
        width: 50%;
        margin-right: 0
    }
    .grid7of12.noGutter {
        width: 58.333%;
        margin-right: 0
    }
}

.flex {
    display: flex
}

.row-centered {
    text-align: center
}

.col-centered {
    display: inline-block;
    float: none;
    text-align: left;
    margin-right: -4px
}

@media (max-width:61.9375em) {
    .no-pad-m {
        padding: 0 !important
    }
}

.m-0 {
    margin: 0
}

.mt-0 {
    margin-top: 0
}

.mb-0 {
    margin-bottom: 0
}

.ml-0 {
    margin-left: 0
}

.mr-0 {
    margin-right: 0
}

.mt-1 {
    margin-top: 8px
}

.mb-1 {
    margin-bottom: 8px
}

.ml-1 {
    margin-left: 8px
}

.mr-1 {
    margin-right: 8px
}

.mt-2 {
    margin-top: 16px
}

.mb-2 {
    margin-bottom: 16px
}

.ml-2 {
    margin-left: 16px
}

.mr-2 {
    margin-right: 16px
}

.mt-3 {
    margin-top: 24px
}

.mb-3 {
    margin-bottom: 24px
}

.ml-3 {
    margin-left: 24px
}

.mr-3 {
    margin-right: 24px
}

.mt-4 {
    margin-top: 32px
}

.mb-4 {
    margin-bottom: 32px
}

.ml-4 {
    margin-left: 32px
}

.mr-4 {
    margin-right: 32px
}

.mr-5,
.timeline {
    margin-right: 40px
}

.mt-5 {
    margin-top: 40px
}

.mb-5 {
    margin-bottom: 40px
}

.ml-5 {
    margin-left: 40px
}

.mt-6 {
    margin-top: 48px
}

.mb-6 {
    margin-bottom: 48px
}

.ml-6 {
    margin-left: 48px
}

.mr-6 {
    margin-right: 48px
}

.mt-7 {
    margin-top: 56px
}

.mb-7 {
    margin-bottom: 56px
}

.ml-7 {
    margin-left: 56px
}

.mr-7 {
    margin-right: 56px
}

.mt-8 {
    margin-top: 64px
}

.mb-8 {
    margin-bottom: 64px
}

.ml-8 {
    margin-left: 64px
}

.mr-8 {
    margin-right: 64px
}

.pt-0 {
    padding-top: 0
}

.pb-0 {
    padding-bottom: 0
}

.pl-0 {
    padding-left: 0
}

.pr-0 {
    padding-right: 0
}

.pt-1 {
    padding-top: 8px
}

.pb-1 {
    padding-bottom: 8px
}

.pl-1 {
    padding-left: 8px
}

.pr-1 {
    padding-right: 8px
}

.pt-2 {
    padding-top: 16px
}

.pb-2 {
    padding-bottom: 16px
}

.pl-2 {
    padding-left: 16px
}

.pr-2 {
    padding-right: 16px
}

.pt-3 {
    padding-top: 24px
}

.pb-3 {
    padding-bottom: 24px
}

.pl-3 {
    padding-left: 24px
}

.pr-3 {
    padding-right: 24px
}

.pt-4 {
    padding-top: 32px
}

.pb-4 {
    padding-bottom: 32px
}

.pl-4 {
    padding-left: 32px
}

.pr-4 {
    padding-right: 32px
}

.pt-5 {
    padding-top: 40px
}

.pb-5 {
    padding-bottom: 40px
}

.pl-5 {
    padding-left: 40px
}

.pr-5 {
    padding-right: 40px
}

.pt-6 {
    padding-top: 48px
}

.pb-6 {
    padding-bottom: 48px
}

.pl-6 {
    padding-left: 48px
}

.pr-6 {
    padding-right: 48px
}

.pt-7 {
    padding-top: 56px
}

.pb-7 {
    padding-bottom: 56px
}

.pl-7 {
    padding-left: 56px
}

.pr-7 {
    padding-right: 56px
}

.pt-8 {
    padding-top: 64px
}

.pb-8 {
    padding-bottom: 64px
}

.pl-8 {
    padding-left: 64px
}

.pr-8 {
    padding-right: 64px
}

.stripe-1 {
    padding: 8px 0
}

@media (max-width:61.9375em) {
    .stripe-1 {
        padding: 4px 0
    }
}

@media (max-width:47.9375em) {
    .stripe-1 {
        padding: 6.4px 0
    }
}

.stripe-2 {
    padding: 16px 0
}

@media (max-width:61.9375em) {
    .stripe-2 {
        padding: 8px 0
    }
}

@media (max-width:47.9375em) {
    .stripe-2 {
        padding: 6.4px 0
    }
}

.stripe-3 {
    padding: 24px 0
}

@media (max-width:61.9375em) {
    .stripe-3 {
        padding: 12px 0
    }
}

@media (max-width:47.9375em) {
    .stripe-3 {
        padding: 9.6px 0
    }
}

.stripe-4 {
    padding: 32px 0
}

@media (max-width:61.9375em) {
    .stripe-4 {
        padding: 16px 0
    }
}

@media (max-width:47.9375em) {
    .stripe-4 {
        padding: 12.8px 0
    }
}

.stripe-5 {
    padding: 40px 0
}

@media (max-width:61.9375em) {
    .stripe-5 {
        padding: 20px 0
    }
}

@media (max-width:47.9375em) {
    .stripe-5 {
        padding: 16px 0
    }
}

.stripe-6 {
    padding: 48px 0
}

@media (max-width:61.9375em) {
    .stripe-6 {
        padding: 24px 0
    }
}

@media (max-width:47.9375em) {
    .stripe-6 {
        padding: 19.2px 0
    }
}

.stripe-7 {
    padding: 56px 0
}

@media (max-width:61.9375em) {
    .stripe-7 {
        padding: 28px 0
    }
}

@media (max-width:47.9375em) {
    .stripe-7 {
        padding: 22.4px 0
    }
}

.stripe-8 {
    padding: 64px 0
}

@media (max-width:61.9375em) {
    .stripe-8 {
        padding: 32px 0
    }
}

@media (max-width:47.9375em) {
    .stripe-8 {
        padding: 25.6px 0
    }
}

.stripe-9 {
    padding: 72px 0
}

@media (max-width:61.9375em) {
    .stripe-9 {
        padding: 36px 0
    }
}

@media (max-width:47.9375em) {
    .stripe-9 {
        padding: 28.8px 0
    }
}

.stripe-10 {
    padding: 80px 0
}

@media (max-width:61.9375em) {
    .stripe-10 {
        padding: 40px 0
    }
}

@media (max-width:47.9375em) {
    .stripe-10 {
        padding: 32px 0
    }
}

.stripe-11 {
    padding: 88px 0
}

@media (max-width:61.9375em) {
    .stripe-11 {
        padding: 44px 0
    }
}

@media (max-width:47.9375em) {
    .stripe-11 {
        padding: 35.2px 0
    }
}

.stripe-12 {
    padding: 96px 0 0
}

@media (max-width:61.9375em) {
    .stripe-12 {
        padding: 48px 0
    }
}

@media (max-width:47.9375em) {
    .stripe-12 {
        padding: 38.4px 0
    }
    .inner-1--m {
        padding: 8px
    }
    .top_6--m {
        padding-top: 48px
    }
}

.inner-1 {
    padding: 8px
}

.inner-2 {
    padding: 16px
}

.inner-3 {
    padding: 24px
}

.inner-4 {
    padding: 32px
}

.inner-5 {
    padding: 40px
}

.inner-6 {
    padding: 48px
}

.inner-7 {
    padding: 56px
}

.inner-8 {
    padding: 64px
}

.inner-9 {
    padding: 72px
}

.inner-10 {
    padding: 80px
}

.inner-11 {
    padding: 88px
}

.inner-12 {
    padding: 96px
}

.distribute {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.distribute-inline {
    display: inline-flex
}

.distribute-vertical {
    -ms-flex-direction: column;
    flex-direction: column
}

.distribute-horizontal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
}

.distribute-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.distribute-center {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.distribute-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.distribute-between {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.event-row,
.login-container,
.modal-container {
    -webkit-box-align: center
}

.tabs {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    list-style: none;
    margin: 0;
    padding: 0
}

.tabs li {
    margin-left: 16px;
    margin-right: 16px;
    border-bottom: 1px solid transparent
}

.tabs li a {
    margin-right: 0;
    padding-right: 4px;
    padding-left: 4px;
    line-height: 1;
    border-bottom: 2px solid transparent;
    -webkit-transition: .2s background-color;
    transition: .2s background-color
}

@media (min-width:48em) {
    .tabs li a {
        margin-right: 24px;
        padding-right: 16px;
        padding-left: 16px
    }
}

.tabs li a:hover {
    background-color: #E3E3E3
}

.tabs a,
.tabs li,
.tabs span {
    padding: 12px 0;
    font-size: 14px;
    line-height: 1.5;
    line-height: inherit;
    color: #5C5C5C;
    user-select: none
}

.menu li,
.timeline .tooltip {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none
}

.tabs li:first-child {
    margin-left: 0
}

.tabs li:last-child {
    margin-right: 0
}

.menu {
    background-color: #fff;
    -webkit-box-shadow: rgba(0, 0, 0, .12) 0 2px 12px, rgba(0, 0, 0, .09) 0 2px 4px;
    box-shadow: rgba(0, 0, 0, .12) 0 2px 12px, rgba(0, 0, 0, .09) 0 2px 4px;
    position: absolute;
    top: 4px;
    list-style: none;
    padding: 8px;
    margin: 0;
    min-width: 240px;
    max-height: 296px;
    overflow-y: scroll;
    z-index: 3
}

.login,
.modal {
    top: -76px;
    right: 0;
    left: 0
}

.menu li,
.table-cell-inner {
    padding-top: 16px;
    padding-bottom: 16px
}

.menu li {
    padding-left: 16px;
    -webkit-transition: .3s all;
    transition: .3s all;
    user-select: none
}

.menu li:hover {
    background-color: #E3E3E3
}

.menu.right {
    right: 0
}

.menu.left {
    left: 0
}

.table {
    display: table
}

.table-row {
    display: table-row
}

.table-cell,
.table-head-cell {
    display: table-cell;
    border-bottom: 1px solid rgba(0, 0, 0, .15)
}

.table-head-cell {
    font-size: 13px;
    font-family: 'Atlas Grotesk Medium';
    font-style: normal;
    vertical-align: middle;
    height: 56px;
    line-height: 1
}

.table-head-cell-width-md {
    width: 64px
}

.table-head-cell-width-l {
    width: 72px
}

.table-head-cell-width-xl {
    width: 180px
}

.table-head-cell-width-remaining {
    width: auto
}

.table-cell {
    font-size: 14px;
    line-height: 1.5;
    height: 54px;
    vertical-align: middle
}

.table-cell-inner {
    padding-right: 16px
}

.modal {
    display: block;
    height: calc(100vh + 76px);
    z-index: 4
}

.modal-overlay {
    height: calc(100vh + 76px);
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .7);
    z-index: 0
}

.modal-container {
    min-height: calc(100% + 46px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    pointer-events: none
}

.modal-container-inner {
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;
    position: relative;
    pointer-events: all
}

.modal .steps-indicator {
    width: 100%;
    text-align: center;
    margin-left: 0;
    padding-left: 0;
    margin-bottom: 32px;
    display: inline-block;
    list-style: none
}

.login-footer,
.modal-footer {
    padding: 16px;
    border-top: 1px solid #E3E3E3
}

.modal .steps-indicator li {
    display: inline-block;
    position: relative;
    width: 80px
}

.modal .steps-indicator li span {
    height: 12px;
    width: 12px;
    display: block;
    position: relative;
    border-radius: 50%;
    background-color: #E3E3E3;
    z-index: 1
}

.modal .steps-indicator li:after {
    position: absolute;
    left: 0;
    top: 4px;
    width: 105%;
    content: '';
    height: 4px;
    background: #E3E3E3;
    margin: 0
}

.modal .steps-indicator li:after:hover {
    cursor: default;
    pointer-events: none
}

.modal .steps-indicator li.active span,
.modal .steps-indicator li.visited span,
.modal .steps-indicator li.visited:after {
    background-color: #6A4AFC
}

.modal .steps-indicator li:last-child {
    width: 12px
}

.modal-footer {
    background-color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%
}

.login {
    position: fixed;
    display: block;
    height: calc(100vh + 76px);
    width: 100vw;
    z-index: 3
}

.login-container-inner,
.login-logo-container {
    width: 446px;
    max-width: 94vw
}

.login-container {
    min-height: calc(100% + 46px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    pointer-events: none
}

.login-container-inner {
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;
    position: relative;
    pointer-events: all;
    -webkit-box-shadow: rgba(0, 0, 0, .07) 0 2px 24px;
    box-shadow: rgba(0, 0, 0, .07) 0 2px 24px
}

.login-footer {
    background-color: #fff;
    bottom: 0;
    width: 100%
}

.shimmer {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: shimmer;
    animation-name: shimmer;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8dfdf), color-stop(28%, #d2d2d2), color-stop(54%, #e8dfdf));
    background: linear-gradient(to right, #e8dfdf 8%, #d2d2d2 28%, #e8dfdf 54%);
    background-size: 1000px 640px;
    border-radius: 4px
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 0
    }
}

.player-loader {
    position: absolute;
    display: block;
    width: 100%;
    height: 16px;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, .2)
}

.player-loader span,
.timeline {
    position: relative;
    display: block
}

.player-loader span {
    height: 16px;
    -webkit-transition: width .4s;
    transition: width .4s;
    background-color: #6A4AFC
}

#load {
    width: 28px;
    -webkit-animation: loading 1.5s linear infinite;
    animation: loading 1.5s linear infinite
}

#load circle {
    stroke-dashoffset: 0;
    stroke-dasharray: 300;
    stroke-width: 10;
    stroke-miterlimit: 10;
    stroke-linecap: round;
    -webkit-animation: loading-circle 1.5s linear infinite;
    animation: loading-circle 1.5s linear infinite;
    fill: transparent
}

@-webkit-keyframes loading {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes loading {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes loading-circle {
    0% {
        stroke-dashoffset: 0
    }
    100% {
        stroke-dashoffset: -600
    }
}

@keyframes loading-circle {
    0% {
        stroke-dashoffset: 0
    }
    100% {
        stroke-dashoffset: -600
    }
}

.timeline {
    margin-left: 60px
}

.timeline .tooltip {
    position: absolute;
    font-size: 12px;
    margin-left: -29px;
    width: 120px;
    pointer-events: none;
    user-select: none
}

.thumbnail .tooltip,
.timeline .channel {
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none
}

.timeline .tooltip span {
    background-color: #fff;
    color: #000;
    position: relative;
    padding: 4px 6px;
    border-radius: 2px
}

.timeline .line {
    background-color: transparent;
    height: 110%;
    pointer-events: none;
    border-right: solid 2px #fff;
    top: -6px;
    position: absolute
}

.timeline .channel {
    height: 30px;
    display: block;
    position: relative;
    background-color: #242323;
    margin-bottom: 8px;
    -webkit-transition: 1s all;
    transition: 1s all;
    user-select: none
}

.timeline .channel .intervals {
    pointer-events: none;
    list-style: none
}

.timeline .channel .intervals li {
    position: absolute;
    height: 100%;
    background-color: #1A1919;
    width: 2px
}

.timeline .video {
    height: 100%;
    position: absolute;
    width: 10px;
    border-radius: 50em;
    overflow: visible
}

.timeline .video:hover {
    background-color: #fff;
    cursor: crosshair
}

.timeline .video.people {
    background-color: #4CABB2
}

.timeline .video.people:hover {
    background-color: #ff0
}

.timeline .video.vehicles {
    background-color: #DE5078
}

.timeline .video.vehicles:hover {
    background-color: #ff0
}

.thumbnail {
    pointer-events: none;
    position: relative
}

.thumbnail .video-thumbnail {
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: 0;
    height: auto;
    width: 280px;
    top: -135px;
    margin-left: -144px;
    z-index: 3
}

.thumbnail .tooltip {
    z-index: 4;
    position: absolute;
    font-size: 12px;
    margin-left: -30px;
    top: -4px;
    width: 120px;
    user-select: none
}

.camera-zone .dot,
.event-card,
.event-card img,
.exclusion-zone .dot {
    -moz-user-select: none;
    -ms-user-select: none
}

.thumbnail .tooltip span {
    background-color: rgba(0, 0, 0, .6);
    color: #fff;
    position: relative;
    padding: 4px 6px;
    border-radius: 2px
}

.zoom-toggle button {
    background-color: transparent;
    padding: 0;
    margin: 0 0 8px
}

.zoom-toggle button svg {
    background-color: #242323;
    color: #fff;
    padding: 4px;
    -webkit-transition: .3s all;
    transition: .3s all
}

.zoom-toggle button svg:hover {
    background-color: #fff;
    color: #000
}

.zoom-toggle button:disabled {
    opacity: .3
}

.timeline .range-select {
    z-index: 2;
    height: 60px;
    margin-top: 6px;
    left: 3px;
    background-color: #ff0
}

.timeline .range-select:hover {
    cursor: ew-resize
}

.timeline::-webkit-scrollbar {
    display: none
}

.offset-nav {
    display: block;
    position: relative;
    padding-top: 92px;
    overflow: hidden
}

.nav-group {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 4
}

.mobile-nav-items,
.nav-overlay {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh
}

.nav {
    display: none;
    background-color: #fff;
    border-bottom: 1px solid #E3E3E3
}

@media (min-width:48em) {
    .offset-nav {
        padding-top: 84px
    }
    .nav {
        display: block
    }
}

.nav-container {
    padding: 0 16px;
    margin: 0 auto
}

.nav-overlay {
    background-color: rgba(255, 255, 255, 0)
}

.nav .user-toggle {
    background-color: #858282;
    color: #fff;
    padding: 6px;
    border-radius: 50em;
    -webkit-transition: .3s all;
    transition: .3s all
}

.nav .user-toggle:hover {
    background-color: #5C5C5C
}

.event-card,
.mobile-nav,
.mobile-nav-items,
.sub-nav {
    background-color: #fff
}

.sub-nav {
    border-bottom: 1px solid #E3E3E3;
    border-top: 1px solid #E3E3E3
}

.sub-nav-container {
    padding: 0 16px;
    margin: 0 auto
}

.mobile-nav {
    display: block
}

@media (min-width:48em) {
    .sub-nav {
        border-top: none
    }
    .mobile-nav {
        display: none
    }
}

.mobile-nav-container {
    padding: 16px;
    margin: 0 auto
}

.mobile-nav ul {
    margin-left: 0;
    padding-left: 0;
    list-style: none
}

.mobile-nav ul.primary li {
    font-size: 32px;
    color: #242323;
    margin-bottom: 8px
}

.mobile-nav ul.primary li a {
    color: #242323
}

.mobile-nav ul.secondary li {
    color: #858282;
    font-size: 16px;
    margin-bottom: 8px
}

.mobile-nav ul.secondary li a {
    color: #858282
}

.event-card {
    width: 48%;
    margin: 0 1% 16px;
    position: relative;
    border-radius: 4px;
    display: inline-block;
    overflow: hidden;
    -webkit-transition: .3s all;
    transition: .3s all;
    -webkit-user-select: none;
    user-select: none
}

.event-card .type-icon {
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .3)), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(180deg, rgba(0, 0, 0, .3) 0, rgba(0, 0, 0, 0) 100%);
    padding-bottom: 40px;
    text-align: right;
    position: absolute;
    width: 100%;
    z-index: 1;
    pointer-events: none
}

.event-card .type-icon-container {
    border-radius: 50em;
    display: inline-block;
    position: relative
}

.event-card .type-icon svg {
    background-color: #fff;
    border-radius: 4px;
    padding: 4px;
    margin: 8px;
    color: #000
}

.event-card p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%
}

.event-card .type-wrapper {
    position: absolute;
    top: -28px;
    left: 15px;
    z-index: 1
}

.event-card .type-wrapper:before {
    position: absolute;
    top: -5px;
    left: -25px;
    display: block;
    width: 76px;
    height: 31px;
    -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3NiAzMSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5iZ19zb3VyY2VfYmFkZ2VfMTwvdGl0bGU+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzEtMiIgZGF0YS1uYW1lPSJMYXllciAxIj48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik01Ny42NywxMS45NEM1Mi4zNiw2LjcxLDQ4LjQyLDAsMzguMywwSDM3LjdDMjcuNTgsMCwyMy42NCw2LjcxLDE4LjMzLDExLjk0LDE0LjQ3LDE2Ljc2LDYuNjEsMTcuODEsMCwxOFYzMUg3NlYxOEM2OS4zOSwxNy44MSw2MS41MywxNi43Niw1Ny42NywxMS45NFoiLz48L2c+PC9nPjwvc3ZnPg==);
    z-index: 0;
    background: #fff
}

.event-card .type-wrapper span {
    position: relative;
    display: block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 26px auto;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    z-index: 1;
    top: -1px
}

.event-card .type-wrapper span svg {
    border-radius: 50em;
    padding: 5px;
    height: 26px;
    width: 26px;
    color: #fff
}

.br-1,
.card,
.person-card {
    border-radius: 4px
}

.event-card .type-wrapper span svg.people {
    background-color: #357490
}

.event-card .type-wrapper span svg.vehicle {
    background-color: #95AD8C
}

@media (min-width:48em) {
    .event-card {
        width: 24%;
        margin: 0 .5% 16px
    }
}

.event-card img {
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none
}

.event-card:hover {
    box-shadow: rgba(0, 0, 0, .2) 0 2px 24px;
    -webkit-transform: translate(0, -4px);
    transform: translate(0, -4px);
    cursor: pointer
}

.event-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.event-row .img-contain {
    max-width: 120px
}

.card {
    padding: 32px;
    background-color: #fff
}

.person-card {
    background-size: cover;
    position: relative;
    overflow: hidden;
    margin-bottom: 24px;
    -webkit-transition: .3s -webkit-box-shadow;
    transition: .3s -webkit-box-shadow;
    transition: .3s box-shadow;
    transition: .3s box-shadow, .3s -webkit-box-shadow
}

.person-card:after,
.person-card:before {
    content: '';
    left: 0;
    position: absolute;
    width: 100%;
    pointer-events: none
}

.person-card:hover {
    -webkit-box-shadow: rgba(0, 0, 0, .3) 2px 8px 16px;
    box-shadow: rgba(0, 0, 0, .3) 2px 8px 16px;
    cursor: pointer
}

.person-card:before {
    height: 50px;
    top: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .3)), color-stop(77%, rgba(0, 0, 0, 0)));
    background: linear-gradient(180deg, rgba(0, 0, 0, .3) 0, rgba(0, 0, 0, 0) 77%)
}

.person-card:after {
    height: 84px;
    bottom: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), color-stop(77%, rgba(0, 0, 0, .5)));
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .5) 77%)
}

.person-card a {
    position: relative;
    display: block;
    height: 100%;
    max-width: 90%;
    text-align: center;
    margin: 0 auto
}

.person-card a p {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1
}

.cameras {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

li.camera-img-card {
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 98%;
    flex: 1 0 98%;
    margin: 0 1% 16px;
    max-width: 100%;
    padding: 0;
    border: none;
    -webkit-box-shadow: 0 2px 40px 0 rgba(0, 0, 0, .007);
    box-shadow: 0 2px 40px 0 rgba(0, 0, 0, .007);
    -webkit-transition: opacity .2s ease-out, -webkit-box-shadow .3s ease-out, -webkit-transform .3s ease-out;
    transition: opacity .2s ease-out, -webkit-box-shadow .3s ease-out, -webkit-transform .3s ease-out;
    transition: box-shadow .3s ease-out, transform .3s ease-out, opacity .2s ease-out;
    transition: box-shadow .3s ease-out, transform .3s ease-out, opacity .2s ease-out, -webkit-box-shadow .3s ease-out, -webkit-transform .3s ease-out;
    -webkit-transition-delay: .1s;
    transition-delay: .1s;
    border-radius: 4px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    background-color: #fff;
    overflow: hidden;
    height: 100%
}

@media (min-width:37.5em) {
    li.camera-img-card {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 48%;
        flex: 1 0 48%;
        margin: 0 1% 16px;
        max-width: 48%
    }
}

@media (min-width:48em) {
    li.camera-img-card {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 24%;
        flex: 1 0 24%;
        margin: 0 .5% 8px;
        max-width: 24%
    }
}

li.camera-img-card p {
    padding: 24px 8px 16px;
    margin: 0 0 0 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

li.camera-img-card .status-wrapper {
    position: absolute;
    top: -12px;
    left: 15px;
    z-index: 1
}

li.camera-img-card .status-wrapper:before {
    position: absolute;
    top: -5px;
    left: -25px;
    content: "";
    display: block;
    width: 76px;
    height: 31px;
    -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3NiAzMSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5iZ19zb3VyY2VfYmFkZ2VfMTwvdGl0bGU+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzEtMiIgZGF0YS1uYW1lPSJMYXllciAxIj48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik01Ny42NywxMS45NEM1Mi4zNiw2LjcxLDQ4LjQyLDAsMzguMywwSDM3LjdDMjcuNTgsMCwyMy42NCw2LjcxLDE4LjMzLDExLjk0LDE0LjQ3LDE2Ljc2LDYuNjEsMTcuODEsMCwxOFYzMUg3NlYxOEM2OS4zOSwxNy44MSw2MS41MywxNi43Niw1Ny42NywxMS45NFoiLz48L2c+PC9nPjwvc3ZnPg==);
    z-index: 0;
    background: #fff
}

li.camera-img-card .img,
li.camera-img-card .status-wrapper span {
    background-repeat: no-repeat;
    background-position: center center;
    position: relative
}

li.camera-img-card .status-wrapper span {
    display: block;
    background-size: 26px auto;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    z-index: 1;
    top: -1px
}

li.camera-img-card .status-wrapper span svg {
    border-radius: 50em;
    padding: 5px;
    height: 26px;
    width: 26px
}

li.camera-img-card .status-wrapper span svg.on {
    background-color: #0A5;
    color: #fff
}

li.camera-img-card .status-wrapper span svg.off {
    background-color: #E3E3E3;
    color: #858282
}

li.camera-img-card:hover {
    opacity: 1 !important;
    -webkit-box-shadow: rgba(0, 0, 0, .2) 0 2px 24px;
    box-shadow: rgba(0, 0, 0, .2) 0 2px 24px;
    -webkit-transform: translate(0, -4px);
    transform: translate(0, -4px)
}

li.camera-img-card .img {
    background-size: cover;
    top: 0;
    background-color: #eee;
    height: 120px;
    width: 100%
}

li.camera-img-card .img.offline {
    opacity: .4
}

.feedback {
    background-color: "red"
}

.camera-zone,
.exclusion-zone {
    background-color: #000;
    background-size: cover;
    display: block;
    width: 100%;
    position: relative
}

.camera-zone .dot,
.exclusion-zone .dot {
    background-color: #6A4AFC;
    border: 4px solid #fff;
    height: 20px;
    width: 20px;
    border-radius: 50em;
    z-index: 2;
    position: absolute;
    -webkit-user-select: none;
    user-select: none;
    -webkit-transition: none;
    transition: none
}

.camera-zone .dot:hover,
.exclusion-zone .dot:hover {
    background-color: #000;
    cursor: -webkit-grab;
    cursor: grab
}

.camera-zone .poly:hover,
.exclusion-zone .poly:hover,
.hover:hover,
.wall-overlay ul li:hover {
    cursor: pointer
}

.camera-zone .poly,
.exclusion-zone .poly {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    border: 2px solid #fff;
    background: rgba(106, 74, 252, .7);
    z-index: 0
}

.exclusion-zone .poly {
    background: rgba(255, 0, 0, .7)
}

.exclusion-zone .dot {
    background-color: red
}

.wall-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2
}

.grid-2 li,
.grid-4 li {
    width: 50%;
    height: 50%
}

.wall-overlay ul {
    margin: 0;
    padding: 0
}

.wall-overlay ul li {
    padding: 0;
    margin: 0;
    position: absolute;
    list-style: none
}

.grid-2 li {
    top: 25%
}

.grid-2 li:nth-of-type(2) {
    left: 50%
}

.grid-4 li:nth-of-type(2) {
    left: 50%
}

.grid-4 li:nth-of-type(3) {
    top: 50%
}

.grid-4 li:nth-of-type(4) {
    left: 50%;
    top: 50%
}

.grid-6 li {
    width: 33.33333%;
    height: 33.3333%
}

.grid-6 li:nth-of-type(1) {
    top: 16.6666%;
    left: 0
}

.grid-6 li:nth-of-type(2) {
    top: 16.6666%;
    left: 33.3333%;
    width: 33.8%
}

.grid-6 li:nth-of-type(3) {
    top: 16.6666%;
    left: 66.6666%
}

.grid-6 li:nth-of-type(4) {
    top: 50%;
    left: 0
}

.grid-6 li:nth-of-type(5) {
    top: 50%;
    left: 33.3333%;
    width: 33.8%
}

.grid-6 li:nth-of-type(6) {
    top: 50%;
    left: 66.6666%
}

.grid-9 li {
    width: 33.33333%;
    height: 33.333333%
}

.grid-9 li:nth-of-type(1) {
    top: 0;
    left: 0
}

.grid-9 li:nth-of-type(2) {
    top: 0;
    left: 33.3333%
}

.grid-9 li:nth-of-type(3) {
    top: 0;
    left: 66.6666%
}

.grid-9 li:nth-of-type(4) {
    top: 33.3333%
}

.grid-9 li:nth-of-type(5) {
    top: 33.3333%;
    left: 33.3333%
}

.grid-9 li:nth-of-type(6) {
    top: 33.3333%;
    left: 66.6666%
}

.grid-9 li:nth-of-type(7) {
    top: 66.6666%
}

.grid-9 li:nth-of-type(8) {
    top: 66.6666%;
    left: 33.3333%
}

.grid-9 li:nth-of-type(9) {
    top: 66.6666%;
    left: 66.6666%
}

.grid-12 li {
    width: 25%;
    height: 25%;
}

.grid-12 li:nth-of-type(1) {
    top: 12.5%;
    left: 0
}

.grid-12 li:nth-of-type(2) {
    top: 12.5%;
    left: 25%
}

.grid-12 li:nth-of-type(3) {
    top: 12.5%;
    left: 50%
}

.grid-12 li:nth-of-type(4) {
    top: 12.5%;
    left: 75%
}

.grid-12 li:nth-of-type(5) {
    top: 37.5%;
    left: 0
}

.grid-12 li:nth-of-type(6) {
    top: 37.5%;
    left: 25%
}

.grid-12 li:nth-of-type(7) {
    top: 37.5%;
    left: 50%
}

.grid-12 li:nth-of-type(8) {
    top: 37.5%;
    left: 75%
}

.grid-12 li:nth-of-type(9) {
    top: 62.5%;
    left: 0
}

.grid-12 li:nth-of-type(10) {
    top: 62.5%;
    left: 25%
}

.grid-12 li:nth-of-type(11) {
    top: 62.5%;
    left: 50%
}

.grid-12 li:nth-of-type(12) {
    top: 62.5%;
    left: 75%
}

.grid-16 li {
    width: 25%;
    height: 25%
}

.grid-16 li:nth-of-type(1) {
    top: 0;
    left: 0
}

.grid-16 li:nth-of-type(2) {
    top: 0;
    left: 25%
}

.grid-16 li:nth-of-type(3) {
    top: 0;
    left: 50%
}

.grid-16 li:nth-of-type(4) {
    top: 0;
    left: 75%
}

.grid-16 li:nth-of-type(5) {
    top: 25%;
    left: 0
}

.grid-16 li:nth-of-type(6) {
    top: 25%;
    left: 25%
}

.grid-16 li:nth-of-type(7) {
    top: 25%;
    left: 50%
}

.grid-16 li:nth-of-type(8) {
    top: 25%;
    left: 75%
}

.grid-16 li:nth-of-type(9) {
    top: 50%;
    left: 0
}

.grid-16 li:nth-of-type(10) {
    top: 50%;
    left: 25%
}

.grid-16 li:nth-of-type(11) {
    top: 50%;
    left: 50%
}

.grid-16 li:nth-of-type(12) {
    top: 50%;
    left: 75%
}

.grid-16 li:nth-of-type(13) {
    top: 75%;
    left: 0
}

.grid-16 li:nth-of-type(14) {
    top: 75%;
    left: 25%
}

.grid-16 li:nth-of-type(15) {
    top: 75%;
    left: 50%
}

.grid-16 li:nth-of-type(16) {
    top: 75%;
    left: 75%
}

@-ms-viewport {
    width: device-width
}

.visible-lg,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block,
.visible-md,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-sm,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-xs,
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block {
    display: none !important
}

@media (max-width:767px) {
    .visible-xs {
        display: block !important
    }
    table.visible-xs {
        display: table
    }
    tr.visible-xs {
        display: table-row !important
    }
    td.visible-xs,
    th.visible-xs {
        display: table-cell !important
    }
    .visible-xs-block {
        display: block !important
    }
    .visible-xs-inline {
        display: inline !important
    }
    .visible-xs-inline-block {
        display: inline-block !important
    }
}

@media (min-width:768px) and (max-width:991px) {
    .visible-sm {
        display: block !important
    }
    table.visible-sm {
        display: table
    }
    tr.visible-sm {
        display: table-row !important
    }
    td.visible-sm,
    th.visible-sm {
        display: table-cell !important
    }
    .visible-sm-block {
        display: block !important
    }
    .visible-sm-inline {
        display: inline !important
    }
    .visible-sm-inline-block {
        display: inline-block !important
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .visible-md {
        display: block !important
    }
    table.visible-md {
        display: table
    }
    tr.visible-md {
        display: table-row !important
    }
    td.visible-md,
    th.visible-md {
        display: table-cell !important
    }
    .visible-md-block {
        display: block !important
    }
    .visible-md-inline {
        display: inline !important
    }
    .visible-md-inline-block {
        display: inline-block !important
    }
}

@media (min-width:1200px) {
    .visible-lg {
        display: block !important
    }
    table.visible-lg {
        display: table
    }
    tr.visible-lg {
        display: table-row !important
    }
    td.visible-lg,
    th.visible-lg {
        display: table-cell !important
    }
    .visible-lg-block {
        display: block !important
    }
    .visible-lg-inline {
        display: inline !important
    }
    .visible-lg-inline-block {
        display: inline-block !important
    }
    .hidden-lg {
        display: none !important
    }
}

@media (max-width:767px) {
    .hidden-xs {
        display: none !important
    }
}

@media (min-width:768px) and (max-width:991px) {
    .hidden-sm {
        display: none !important
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .hidden-md {
        display: none !important
    }
}

.visible-print {
    display: none !important
}

@media print {
    .visible-print {
        display: block !important
    }
    table.visible-print {
        display: table
    }
    tr.visible-print {
        display: table-row !important
    }
    td.visible-print,
    th.visible-print {
        display: table-cell !important
    }
}

.visible-print-block {
    display: none !important
}

@media print {
    .visible-print-block {
        display: block !important
    }
}

.visible-print-inline {
    display: none !important
}

@media print {
    .visible-print-inline {
        display: inline !important
    }
}

.visible-print-inline-block {
    display: none !important
}

@media print {
    .visible-print-inline-block {
        display: inline-block !important
    }
    .hidden-print {
        display: none !important
    }
}

.full-width {
    width: 100%
}

.full-width-viewport {
    width: 100vw;
    overflow: scroll
}

.full-height-viewport {
    height: 100vh;
    overflow: scroll
}

.inline-block {
    display: inline-block
}

.inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.block {
    display: block
}

.relative {
    position: relative
}

.pull-right {
    float: right
}

.uppercase {
    text-transform: uppercase
}

.text-right {
    text-align: right
}

.text-center {
    text-align: center
}

.text-left {
    text-align: left
}

.list-style-none {
    list-style: none
}

.border-bottom {
    border-bottom: 1px solid #E9EDF2
}

.br-2 {
    border-radius: 8px
}

.is-hidden {
    display: none
}

.visible-overflow {
    overflow: visible
}

.no-hover {
    pointer-events: none
}

.no-hover:hover {
    pointer-events: none;
    cursor: default
}

.status-check {
    position: relative;
    display: block;
    padding: 5px;
    border-radius: 50em;
    border: 2px solid #0A5;
    stroke: #0A5
}